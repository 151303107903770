@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-Light.eot');
	src: url('AkzidenzGroteskPro-Light.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-Light.woff2') format('woff2'),
	url('AkzidenzGroteskPro-Light.woff') format('woff'),
	url('AkzidenzGroteskPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-LightIt.eot');
	src: url('AkzidenzGroteskPro-LightIt.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-LightIt.woff2') format('woff2'),
	url('AkzidenzGroteskPro-LightIt.woff') format('woff'),
	url('AkzidenzGroteskPro-LightIt.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-Regular.eot');
	src: url('AkzidenzGroteskPro-Regular.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-Regular.woff2') format('woff2'),
	url('AkzidenzGroteskPro-Regular.woff') format('woff'),
	url('AkzidenzGroteskPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-It.eot');
	src: url('AkzidenzGroteskPro-It.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-It.woff2') format('woff2'),
	url('AkzidenzGroteskPro-It.woff') format('woff'),
	url('AkzidenzGroteskPro-It.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-Md.eot');
	src: url('AkzidenzGroteskPro-Md.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-Md.woff2') format('woff2'),
	url('AkzidenzGroteskPro-Md.woff') format('woff'),
	url('AkzidenzGroteskPro-Md.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-MdIt.eot');
	src: url('AkzidenzGroteskPro-MdIt.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-MdIt.woff2') format('woff2'),
	url('AkzidenzGroteskPro-MdIt.woff') format('woff'),
	url('AkzidenzGroteskPro-MdIt.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-Bold.eot');
	src: url('AkzidenzGroteskPro-Bold.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-Bold.woff2') format('woff2'),
	url('AkzidenzGroteskPro-Bold.woff') format('woff'),
	url('AkzidenzGroteskPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-BoldIt.eot');
	src: url('AkzidenzGroteskPro-BoldIt.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-BoldIt.woff2') format('woff2'),
	url('AkzidenzGroteskPro-BoldIt.woff') format('woff'),
	url('AkzidenzGroteskPro-BoldIt.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-XBd.eot');
	src: url('AkzidenzGroteskPro-XBd.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-XBd.woff2') format('woff2'),
	url('AkzidenzGroteskPro-XBd.woff') format('woff'),
	url('AkzidenzGroteskPro-XBd.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Akzidenz-Grotesk Pro';
	src: url('AkzidenzGroteskPro-XBdIt.eot');
	src: url('AkzidenzGroteskPro-XBdIt.eot?#iefix') format('embedded-opentype'),
	url('AkzidenzGroteskPro-XBdIt.woff2') format('woff2'),
	url('AkzidenzGroteskPro-XBdIt.woff') format('woff'),
	url('AkzidenzGroteskPro-XBdIt.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}